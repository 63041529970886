import {
  Avatar,
  CircularProgress,
  colors,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useAssignJob } from 'src/firebase';
import { RegionContext } from 'src/providers/RegionProvider';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  avatar: {
    fontSize: 10,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  selectProgress: {
    position: 'absolute',
    top: '50%',
    right: 24,
    marginLeft: -6
  }
}));

function OrderAssignListItem({ className, job, ...rest }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [assign] = useAssignJob();
  const { enqueueSnackbar } = useSnackbar();

  const techs = useSelector(state => state.firestore.ordered.techs) || [];
  const shopMap = useSelector(state => state.firestore.data.shops) || {};

  const availableTechs = techs.filter(tech => tech.status === 'available');

  const { region } = useContext(RegionContext);
  const distanceBetween = (tech, job) => {
    if (
      !tech ||
      !job ||
      !tech.location ||
      tech.location === 'none' ||
      tech.location === 'None'
    ) {
      return Number.MAX_VALUE;
    }

    const techShop = shopMap[tech.location];
    const jobShop = shopMap[job.shop];

    // eslint-disable-next-line no-use-before-define
    return distance(
      techShop?.lat,
      techShop?.lng,
      jobShop?.lat,
      jobShop?.lng
    ).toFixed(1);
  };

  const getSortedTechs = job => {
    return (availableTechs || [])
      .map(tech => {
        return { ...tech, distance: distanceBetween(tech, job) };
      })
      .sort((a, b) => a.distance - b.distance);
  };

  const handleChange = (evt, job) => {
    setLoading(true);
    const id = evt.target.value;
    assign(region, job.id, job.assignee, id)
      .then(() => {
        enqueueSnackbar(
          `Job ${id === 'Unassigned' ? 'unassigned' : 'assigned'}`,
          { variant: 'success' }
        );
      })
      .catch(err => {
        enqueueSnackbar('Error assigning job: ' + err.message, {
          variant: 'error'
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <ListItem divider={true} key={job.id}>
      <ListItemAvatar>
        <IconButton
          size="small"
          component={RouterLink}
          to={`/orders/${job.id}`}
        >
          <Avatar
            className={classes.avatar}
            size="small"
            style={{ backgroundColor: colors.grey[500] }}
          >
            {job.type === 'Repair' ? <BuildIcon /> : <AssignmentIcon />}
          </Avatar>
        </IconButton>
      </ListItemAvatar>

      <ListItemText primary={shopMap[job.shop]?.name} secondary={job.date} />
      <div className={classes.wrapper}>
        <TextField
          label="Assignee"
          name="assignee"
          fullWidth
          className={classes.select}
          onChange={evt => handleChange(evt, job)}
          select
          SelectProps={{ native: true }}
          disabled={loading}
          placeholder="Unassigned"
          value={
            (techs.find(tech => job.assignee === tech.id) || {}).id ||
            'Unassigned'
          }
        >
          <option value="Unassigned">Unassigned</option>
          {getSortedTechs(job).map(tech => (
            <option key={tech.id} value={tech.id}>
              {`${tech.name} ${
                tech.distance !== Number.MAX_VALUE ? `(${tech.distance} m)` : ''
              }`}
            </option>
          ))}
        </TextField>
        {loading && (
          <CircularProgress size={16} className={classes.selectProgress} />
        )}
      </div>
    </ListItem>
  );
}

function distance(lat1, lon1, lat2, lon2, unit) {
  if (String(lat1) === String(lat2) && String(lon1) === String(lon2)) {
    return 0;
  }
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist *= 1.609344;
  }
  if (unit === 'N') {
    dist *= 0.8684;
  }
  return dist;
}

OrderAssignListItem.propTypes = {
  className: PropTypes.string,
  jobs: PropTypes.array,
  job: {
    id: PropTypes.string,
    assignee: PropTypes.string,
    type: PropTypes.string,
    date: PropTypes.string
  }
};

export default OrderAssignListItem;
