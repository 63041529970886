/* eslint-disable no-unused-vars */
import {
  AppBar,
  Button,
  colors,
  FormControl,
  Hidden,
  MenuItem,
  Select,
  Toolbar,
  Dialog,
  Typography,
  CircularProgress
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import InputIcon from '@material-ui/icons/Input';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { makeStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import PricingModal from 'src/components/PricingModal';
import { RegionContext } from 'src/providers/RegionProvider';
import logo from '../../assets/logoSmall.png'; // with import
const fileDownload = require('js-file-download');

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flex: {
    display: 'flex'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  chatButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  mb3: {
    marginBottom: '0.25em',
    marginTop: '0.25em'
  },
  activeLink: {
    borderBottomColor: theme.palette.pills.color,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid'
  },
  navLinkItem: {
    color: theme.palette.pills.color,
    marginLeft: '8px',
    padding: '6px 8px',
    letterSpacing: '0.02857em',
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontFamily: 'Helvetica'
  },
  navImage: {
    maxWidth: '60px'
  },
  actions: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  startButton: {
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  header: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  exportBtn: {
    whiteSpace: 'no-wrap'
  },
  progress: {
    marginRight: theme.spacing(1)
  }
}));

const popularSearches = [
  'Devias React Dashboard',
  'Devias',
  'Admin Pannel',
  'Project',
  'Pages'
];

function TopBar({ onOpenNavBarMobile, className, resetRegion, ...rest }) {
  const classes = useStyles();
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const firebase = useFirebase();

  const [regionDropdownVal, setRegionDropdownVal] = useState('');

  const { region, setRegion } = useContext(RegionContext);

  const regions = useSelector(state => state.firestore.ordered.regions);

  const thisRegion = regions?.find(({ id }) => id === region);

  const [inviteLinkModalOpen, setInviteLinkModalOpen] = useState(false);

  const [exportingJobs, setExportingJobs] = useState(false);

  const handleInviteLinkModalOpen = () => {
    setInviteLinkModalOpen(true);
  };

  const handleInviteLinkModalClose = () => {
    setInviteLinkModalOpen(false);
  };

  const exportJobs = () => {
    setExportingJobs(true);
    const runExport = firebase.functions().httpsCallable('exportAllJobs');
    return runExport().then(data => {
      fileDownload(data.data, 'jobs.csv', 'text/csv');
      setExportingJobs(false);
    });
  };

  useEffect(() => {
    setRegionDropdownVal(region);
  }, [region, setRegionDropdownVal]);

  const handleLogout = () => {
    firebase.logout();
    // history.push('/auth/login');
    // dispatch(logout());
  };

  const handlePricingModalClose = () => {
    setPricingModalOpen(false);
  };

  const configureRegion = useCallback(
    region => {
      setRegionDropdownVal(region);
      if (region === 'All') {
        return resetRegion();
      }
      setRegion(region);
      localStorage.setItem('REGION', region);
    },
    [setRegion, resetRegion, setRegionDropdownVal]
  );

  const BootstrapInput = withStyles(theme => ({
    input: {
      color: theme.palette.pills.color,
      marginLeft: '8px',
      padding: '6px 8px',
      letterSpacing: '0.02857em',
      display: 'inline-flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Helvetica'
    }
  }))(InputBase);

  const SmallBootstrapInput = withStyles(theme => ({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    }
  }))(InputBase);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <Hidden smDown>
          <img src={logo} alt="Logo" className={classes.navImage} />
          <Button
            className={classes.logoutButton}
            color="inherit"
            component={RouterLink}
            to="/"
          >
            <DashboardIcon className={classes.logoutIcon} />
            Dashboard
          </Button>
          <NavLink
            to="/techs"
            className={classes.navLinkItem}
            activeClassName={classes.activeLink}
          >
            <PeopleIcon className={classes.logoutIcon} />
            Techs
          </NavLink>
          <NavLink
            to="/orders"
            className={classes.navLinkItem}
            activeClassName={classes.activeLink}
          >
            <AssignmentIcon className={classes.logoutIcon} />
            Orders
          </NavLink>
          <NavLink
            to="/shops"
            className={classes.navLinkItem}
            activeClassName={classes.activeLink}
          >
            <BusinessIcon className={classes.logoutIcon} />
            Shops
          </NavLink>
          {/* <NavLink
            to="/projects"
            className={classes.navLinkItem}
            activeClassName={classes.activeLink}
          >
            <BusinessCenterIcon className={classes.logoutIcon} />
            Projects
          </NavLink> */}

          <NavLink
            to="/calendar"
            className={classes.navLinkItem}
            activeClassName={classes.activeLink}
          >
            <CalendarTodayIcon className={classes.logoutIcon} />
            Calendar
          </NavLink>
          <div className={classes.flexGrow} />
          {!window.location.href.includes('/regions') && (
            <FormControl className={classes.formControl}>
              <Select
                labelId="region-select-label"
                id="region-select"
                onChange={e => configureRegion(e.target.value)}
                input={<SmallBootstrapInput />}
                displayEmpty
                value={regionDropdownVal}
              >
                <MenuItem value="" disabled>
                  Select Region
                </MenuItem>
                {(regions || []).map(region => (
                  <MenuItem key={region.id} value={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
                <MenuItem value="All">Configure Regions</MenuItem>
              </Select>
            </FormControl>
          )}
          {region && (
            <Button
              className={classes.logoutButton}
              color="inherit"
              variant="outlined"
              onClick={handleInviteLinkModalOpen}
            >
              Invite Tech
            </Button>
          )}
          <Button
            className={`${classes.logoutButton} ${classes.exportBtn}`}
            color="inherit"
            variant="outlined"
            onClick={() => {
              !exportingJobs && exportJobs();
            }}
          >
            {exportingJobs && (
              <CircularProgress
                color={colors.grey[800]}
                size="20%"
                className={classes.progress}
              />
            )}
            Export jobs
          </Button>
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon className={classes.logoutIcon} />
            Sign out
          </Button>
        </Hidden>
        <Hidden mdUp>
          <div className="flexRow">
            <NavLink to="/" className={classes.navLinkItem}>
              Dashboard
            </NavLink>
            <NavLink
              to="/techs"
              className={classes.navLinkItem}
              activeClassName={classes.activeLink}
            >
              Techs
            </NavLink>
            <NavLink
              to="/orders"
              className={classes.navLinkItem}
              activeClassName={classes.activeLink}
            >
              Orders
            </NavLink>
            <NavLink
              to="/shops"
              className={classes.navLinkItem}
              activeClassName={classes.activeLink}
            >
              Shops
            </NavLink>
            {/* <NavLink
              to="/projects"
              className={classes.navLinkItem}
              activeClassName={classes.activeLink}
            >
              Projects
            </NavLink> */}
            <NavLink
              to="/calendar"
              className={classes.navLinkItem}
              activeClassName={classes.activeLink}
            >
              Calendar
            </NavLink>
            <FormControl className={classes.formControl}>
              <Select
                labelId="region-select-label"
                id="region-select"
                onChange={e => configureRegion(e.target.value)}
                value={regionDropdownVal}
                input={<BootstrapInput />}
                displayEmpty
              >
                <MenuItem value="All" disabled>
                  Select Region
                </MenuItem>
                {(regions || []).map(region => (
                  <MenuItem key={region.id} value={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {region && (
              <Button
                className={classes.logoutButton}
                color="inherit"
                variant="outlined"
                onClick={handleInviteLinkModalOpen}
              >
                Invite Tech
              </Button>
            )}
            <Button
              className={classes.logoutButton}
              color="inherit"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
        </Hidden>
      </Toolbar>
      <PricingModal onClose={handlePricingModalClose} open={pricingModalOpen} />
      <Dialog
        maxWidth="lg"
        onClose={handleInviteLinkModalClose}
        open={inviteLinkModalOpen}
      >
        <div>
          <div className={classes.header}>
            <Typography align="center" gutterBottom variant="h3">
              Want to invite a tech to the {thisRegion?.name ?? 'current'}{' '}
              region?
            </Typography>
            <Typography
              align="center"
              className={classes.subtitle}
              variant="subtitle2"
            >
              Copy this invite link:
              <br />
              <span>
                https://sw-pwa-ca.tech-scheduler.com/signup?regionID={region}
              </span>
            </Typography>
          </div>
          <div className={classes.actions}>
            <Button
              className={classes.startButton}
              onClick={handleInviteLinkModalClose}
              variant="contained"
            >
              Close Dialog
            </Button>
          </div>
        </div>
      </Dialog>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  resetRegion: PropTypes.func
};

export default TopBar;
