import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, colors, Link } from '@material-ui/core';
import Label from 'src/components/Label';

// import Label from 'src/components/Label';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      // '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.divider}`
      // }
    }
  },
  itemsContainer: {
    backgroundColor: theme.palette.pills.background,
    color: theme.palette.pills.color
  },
  textWhite: { color: theme.palette.pills.color },
  valueContainer: {
    display: 'flex',
    alignItems: theme.palette.pills.color,
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  }
}));

function Overview({ className, ...rest }) {
  const classes = useStyles();

  const jobs = useSelector(state => state.firestore.ordered.jobs) || [];

  const pendingJobs = jobs.filter(job => job.status === 'pending');
  const activeJobs = jobs.filter(job => job.status === 'active');
  const completedJobs = jobs.filter(job => job.status === 'complete');

  const todayJobs = jobs.filter(job => {
    return moment(job.date).isSame(moment(), 'days');
  });

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="center"
        className={classes.itemsContainer}
        container
        justify="space-between"
      >
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h1" gutterBottom variant="overline">
            Pending Jobs
          </Typography>
          <div className={classes.valueContainer}>
            <Link component={RouterLink} to={`/orders?tab=pending`}>
              <Typography className={classes.textWhite} variant="h3">
                {pendingJobs.length}
              </Typography>
            </Link>
          </div>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h1" gutterBottom variant="overline">
            Active Jobs
          </Typography>
          <div className={classes.valueContainer}>
            <Link component={RouterLink} to={`/orders?tab=active`}>
              <Typography variant="h3" className={classes.textWhite}>
                {activeJobs.length}
              </Typography>
            </Link>
            {/* <Label
              className={classes.label}
              color={colors.red[600]}
              variant="contained"
            >
              -20%
            </Label> */}
          </div>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h1" gutterBottom variant="overline">
            Completed Jobs
          </Typography>
          <div className={classes.valueContainer}>
            <Link component={RouterLink} to={`/orders?tab=complete`}>
              <Typography variant="h3" className={classes.textWhite}>
                {completedJobs.length}
              </Typography>
            </Link>
          </div>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography component="h1" gutterBottom variant="overline">
            Total Jobs
          </Typography>
          <div className={classes.valueContainer}>
            <Link component={RouterLink} to={`/orders?tab=all`}>
              <Typography variant="h3" className={classes.textWhite}>
                {(jobs || []).length}
              </Typography>
            </Link>
            <Label
              className={classes.label}
              color={colors.orange[400]}
              variant="contained"
            >
              {`${todayJobs.length} today`}
            </Label>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

Overview.propTypes = {
  className: PropTypes.string
};

export default Overview;
