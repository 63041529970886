import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';
import { useFirestore } from 'react-redux-firebase';

const { arrayRemove, arrayUnion } = firebase.firestore.FieldValue;

if (!firebase.apps.length) {
  // Initialize Firebase
  firebase.initializeApp({
    apiKey: 'AIzaSyBvGMB5QFOfj6NjPDQSBZ9fu8XYokIpSA4',
    authDomain: 'techrepair-sw-canada.firebaseapp.com',
    projectId: 'techrepair-sw-canada',
    storageBucket: 'techrepair-sw-canada.appspot.com',
    messagingSenderId: '120361292390',
    appId: '1:120361292390:web:8b260223e84992374853a7'
  });
  if (
    window.location.href.includes('http://localhost') &&
    [true, 'true'].includes(process.env.REACT_APP_USE_FIREBASE_EMULATOR)
  ) {
    firebase
      .functions()
      .useEmulator('localhost', process.env.REACT_APP_FIREBASE_EMULATOR_PORT);
  }
  firebase.firestore();
}

export default firebase;

export function useAssignJob() {
  const firestore = useFirestore();

  return [
    async (regionId, orderId, oldTechId, newTechId) => {
      newTechId = newTechId === 'Unassigned' ? null : newTechId;
      oldTechId = oldTechId === 'Unassigned' ? null : oldTechId;

      const batch = firestore.batch();
      const jobRef = firestore
        .collection(`/regions/${regionId}/jobs`)
        .doc(orderId);

      if (oldTechId) {
        const oldTechRef = firestore
          .collection(`/regions/${regionId}/techs`)
          .doc(oldTechId);
        batch.update(oldTechRef, {
          jobs: arrayRemove(orderId)
        });
      }

      if (newTechId) {
        const newTechRef = firestore
          .collection(`/regions/${regionId}/techs`)
          .doc(newTechId);
        batch.update(newTechRef, {
          jobs: arrayUnion(orderId)
        });
      }

      batch.update(jobRef, {
        assignee: newTechId
      });

      await batch.commit();
    }
  ];
}

export function useDeleteOrder(regionId, { onComplete }) {
  const firestore = useFirestore();

  return [
    async orderId => {
      const batch = firestore.batch();
      const orderRef = firestore
        .collection(`/regions/${regionId}/jobs`)
        .doc(orderId);

      const snap = await firestore
        .collection(`/regions/${regionId}/techs`)
        .where('jobs', 'array-contains', orderId)
        .get();
      snap.forEach(doc => {
        console.log('removing from doc');
        batch.update(doc.ref, {
          jobs: arrayRemove(orderId)
        });
      });

      batch.delete(orderRef);

      await batch.commit();

      if (onComplete) {
        onComplete();
      }
    }
  ];
}
