import { colors } from '@material-ui/core';

const white = '#FFFFFF';

export default {
  primary: {
    contrastText: white,
    dark: '#5e35b1', // CHANGE FOR REBRANDING
    main: '#5e35b1', // CHANGE FOR REBRANDING
    light: '#5e35b1' // CHANGE FOR REBRANDING
  },
  secondary: {
    contrastText: white,
    dark: '#5e35b1', // CHANGE FOR REBRANDING
    main: colors.grey.A700,
    light: colors.grey.A400
  },
  error: {
    contrastText: white,
    dark: colors.pink[900],
    main: colors.pink[600],
    light: colors.pink[400]
  },
  text: {
    primary: colors.grey[600],
    link: colors.grey[600]
  },
  link: colors.deepPurple[800],
  icon: colors.grey[600],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  calendar: {
    eventBackground: '#5e35b1', // CHANGE FOR REBRANDING
    eventBackgroundBorder: '#9575cd',
    todayDate: '#c8e6c9'
  },
  auth: {
    background: '#5e35b1' // CHANGE FOR REBRANDING
  },
  pills: {
    background: '#42a5f5',
    color: 'white'
  },
  anchorActive: 'white',
  anchor: colors.grey[400],
  divider: colors.grey[200]
};
