import { Button, colors, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Label from 'src/components/Label';

const statusColors = {
  active: colors.blue[600],
  pending: colors.orange[600],
  complete: colors.green[600]
};

function OrderTable({
  interactable = true,
  orders,
  title = 'Orders',
  filter,
  onFilterChange
}) {
  const shopMap = useSelector(state => state.firestore.data.shops);
  const techMap = useSelector(state => state.firestore.data.techs);

  const formatted = orders.map((order, i) => {
    return [
      order.type,
      moment(order.date).valueOf(),
      order.status,
      order.shop,
      order.assignee,
      <Button
        key={i}
        to={`/orders/${order.id}`}
        component={RouterLink}
        color="primary"
        size="small"
        variant="outlined"
      >
        View
      </Button>
    ];
  });

  const isSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const columns = [
    {
      label: 'Type',
      options: {
        display: !isSm,
        customBodyRender: value => {
          return (
            <div style={{ display: 'flex' }}>
              <Tooltip title={value} placement="right">
                {value === 'Repair' ? (
                  <BuildIcon htmlColor={colors.grey[500]} />
                ) : (
                  <AssignmentIcon htmlColor={colors.grey[500]} />
                )}
              </Tooltip>
            </div>
          );
        }
      }
    },
    {
      label: 'Date',
      options: {
        filter: false,
        sortDirection: 'desc',
        customBodyRender: value => {
          return moment(value).format('MM/DD/YYYY HH:mm');
        }
      }
    },
    {
      label: 'Status',
      options: {
        filterList: filter && filter !== 'all' ? [filter] : null,
        customBodyRender: value => {
          return (
            <Label color={statusColors[value]} variant="outlined">
              {value}
            </Label>
          );
        }
      }
    },
    {
      label: 'Shop',
      options: {
        display: !isSm,
        customBodyRender: value => {
          if (!shopMap[value]) {
          }
          return shopMap[value] ? shopMap[value].name : '';
        }
      }
    },
    {
      label: 'Assignee',
      options: {
        display: !isSm,
        customBodyRender: value => {
          if (!value || !techMap[value]) {
            return '';
          }
          return techMap[value].name;
        }
      }
    },
    {
      label: 'Actions',
      options: {
        filter: false
      }
    }
  ];

  return (
    <MUIDataTable
      title={title}
      options={{
        elevation: interactable ? 1 : 0,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: 'none',
        responsive: !isSm ? 'scrollFullHeight' : 'scrollMaxHeight',
        resizableColumns: false,
        pagination: interactable,
        filter: interactable,
        search: interactable,
        onFilterChange: onFilterChange
      }}
      data={formatted}
      columns={columns}
    />
  );
}

OrderTable.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array,
  interactable: PropTypes.bool,
  title: PropTypes.string,
  filter: PropTypes.bool,
  onFilterChange: PropTypes.func
};

OrderTable.defaultProps = {
  orders: []
};

export default OrderTable;
